import { ViteSSG } from 'vite-ssg'
import { setupLayouts } from 'virtual:generated-layouts'

import type { UserModule } from '~/types'
import generatedRoutes from '~pages'
import App from '~/App.vue'
import '~/css/main.css'
import { ROUTES } from '~/common/routes'

const routes = setupLayouts(generatedRoutes)

// https://github.com/antfu/vite-ssg
export const createApp = ViteSSG(
  App,
  {
    routes,
    scrollBehavior() {
      return {
        top: 0,
        left: 0,
      }
    },
    base: import.meta.env.BASE_URL,
  },
  (ctx) => {
    Object.values(import.meta.glob<{ install: UserModule }>('./plugins/*.ts', { eager: true }))
      .forEach(i => i.install?.(ctx))

    if (ctx.isClient) {
      ctx.router.beforeEach((to, from, next) => {
        if (to.path === ROUTES.HOME) {
          next({ path: `/${ctx.app.config.globalProperties.$i18n.locale}` })
          return
        }

        if (to.path !== from.path) // exc. hash change
          document.documentElement.style.scrollBehavior = 'auto'

        next()
      })

      ctx.router.afterEach(() => {
        setTimeout(() => document.documentElement.style.scrollBehavior = 'smooth', 100)
      })
    }
  },
)
