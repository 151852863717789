import __pages_import_0__ from "/src/views/index.vue";
const __pages_import_1__ = () => import("/src/views/[...all].vue");
const __pages_import_2__ = () => import("/src/views/[locale(en|fr|es)]/contact.vue");
const __pages_import_3__ = () => import("/src/views/[locale(en|fr|es)]/gallery.vue");
const __pages_import_4__ = () => import("/src/views/[locale(en|fr|es)]/hotel.vue");
const __pages_import_5__ = () => import("/src/views/[locale(en|fr|es)]/index.vue");
const __pages_import_6__ = () => import("/src/views/[locale(en|fr|es)]/legal-notice.vue");
const __pages_import_7__ = () => import("/src/views/[locale(en|fr|es)]/location.vue");
const __pages_import_8__ = () => import("/src/views/[locale(en|fr|es)]/privacy-policy.vue");
const __pages_import_9__ = () => import("/src/views/[locale(en|fr|es)]/rooms.vue");
const __pages_import_10__ = () => import("/src/views/[locale(en|fr|es)]/sales-terms.vue");
const __pages_import_11__ = () => import("/src/views/[locale(en|fr|es)]/services.vue");

const routes = [{"name":"index","path":"/","component":__pages_import_0__,"props":true},{"name":"all","path":"/:all(.*)*","component":__pages_import_1__,"props":true,"meta":{"layout":404}},{"name":"locale(en|fr|es)-contact","path":"/:locale(en|fr|es)/contact","component":__pages_import_2__,"props":true},{"name":"locale(en|fr|es)-gallery","path":"/:locale(en|fr|es)/gallery","component":__pages_import_3__,"props":true},{"name":"locale(en|fr|es)-hotel","path":"/:locale(en|fr|es)/hotel","component":__pages_import_4__,"props":true},{"name":"locale(en|fr|es)","path":"/:locale(en|fr|es)","component":__pages_import_5__,"props":true},{"name":"locale(en|fr|es)-legal-notice","path":"/:locale(en|fr|es)/legal-notice","component":__pages_import_6__,"props":true},{"name":"locale(en|fr|es)-location","path":"/:locale(en|fr|es)/location","component":__pages_import_7__,"props":true},{"name":"locale(en|fr|es)-privacy-policy","path":"/:locale(en|fr|es)/privacy-policy","component":__pages_import_8__,"props":true},{"name":"locale(en|fr|es)-rooms","path":"/:locale(en|fr|es)/rooms","component":__pages_import_9__,"props":true},{"name":"locale(en|fr|es)-sales-terms","path":"/:locale(en|fr|es)/sales-terms","component":__pages_import_10__,"props":true},{"name":"locale(en|fr|es)-services","path":"/:locale(en|fr|es)/services","component":__pages_import_11__,"props":true}];

export default routes;