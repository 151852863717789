<script setup lang="ts">
import { MapPinIcon, PhoneIcon } from '@heroicons/vue/24/outline/index.js'
import { ROUTES } from '~/common/routes'
import { PHONE_NUMBER, PHONE_NUMBER_RAW } from '~/common/types'
import { useTranslation } from '~/composables'

const { withLocale } = useTranslation()
</script>

<template>
  <div class="bg-secondary-500">
    <div class="max-w-7xl mx-auto xs:px-4">
      <div class="flex justify-between text-xs">
        <div class="md:space-x-2 flex">
          <div class="p-2 h-full items-center space-x-2 font-semibold hidden md:inline-flex">
            <MapPinIcon class="h-4 w-4"
                        aria-hidden="true"
            />
            <span>{{ $t('project.address') }}</span>
          </div>
          <div class="w-px bg-gray-300 hidden md:block" />
          <a :href="`tel:${PHONE_NUMBER_RAW}`"
             class="p-2 h-full inline-flex items-center space-x-2 font-semibold hover:underline"
          >
            <PhoneIcon class="h-4 w-4" aria-hidden="true" />
            <span>{{ PHONE_NUMBER }}</span>
          </a>
          <div class="ml-2 w-px bg-gray-300 md:hidden" />
        </div>
        <div class="space-x-2 flex">
          <router-link :to="withLocale(ROUTES.CONTACT).value"
                       class="p-2 h-full inline-flex items-center font-semibold hover:underline"
          >
            <span>{{ $t('modules.contact.contact-us') }}</span>
          </router-link>
          <div class="w-px bg-gray-300" />
          <router-link :to="withLocale(ROUTES.LOCATION).value"
                       class="p-2 h-full inline-flex items-center font-semibold hover:underline"
          >
            <span>{{ $t('modules.contact.find-us') }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
