import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

export default function useTranslation() {
  const { locale } = useI18n()

  return {
    withLocale: (route: string) => {
      return computed(() => `/${locale.value}${route}`)
    },
  }
}
