<script setup>
import { ROUTES } from '~/common/routes'
import { useTranslation } from '~/composables'

const { withLocale } = useTranslation()

const navigation = [
  { name: 'modules.nav.contact', path: ROUTES.CONTACT },
  { name: 'modules.nav.legals', path: ROUTES.LEGALS },
  { name: 'modules.nav.confidentiality', path: ROUTES.CONFIDENTIALITY },
  { name: 'modules.nav.sales', path: ROUTES.SALES },
]
</script>

<template>
  <footer class="relative z-10 bg-primary-500 border-t">
    <div class="max-w-7xl mx-auto px-4 py-6">
      <div class="flex flex-col flex-wrap items-center justify-between text-sm text-gray-100 space-y-4 lg:flex-row lg:space-y-0">
        <div>
          &copy; {{ new Date().getFullYear() }} {{ $t('project.name') }} - {{ $t('modules.footer.copyright') }}
        </div>
        <nav class="flex flex-wrap items-center justify-center lg:justify-start space-x-4">
          <router-link v-for="item in navigation"
                       :key="item.name"
                       :to="withLocale(item.path)"
                       class="p-2 -m-2 hover:underline"
          >
            {{ $t(item.name) }}
          </router-link>
        </nav>
      </div>
    </div>
  </footer>
</template>
