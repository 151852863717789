<script setup lang="ts">
import { defineAsyncComponent, onMounted, ref } from 'vue'
import { TheContact, TheFooter, TheHeader } from '~/components/single-instance'
import { isProduction } from '~/common/utils'

const TheInstaFeed = defineAsyncComponent(() =>
  import('~/components/single-instance/the-insta-feed.vue'),
)

const isLoaded = ref(false)

onMounted(() => {
  isLoaded.value = true
})
</script>

<template>
  <main class="min-h-screen flex flex-col text-gray-800 bg-secondary-500">
    <TheContact />
    <TheHeader />
    <div class="flex-1 flex flex-col relative">
      <RouterView />
    </div>
    <template v-if="isLoaded && isProduction">
      <TheInstaFeed />
    </template>
    <TheFooter />
  </main>
</template>
