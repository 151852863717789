project:
  name: El Cónsul Maó
  description: 'L''ancien consulat de France à Mahón devient un petit hôtel Cosy - 6 chambres - ouverture juillet 2022'
  address: 'Carrer d''en Deià, 30, 07702 Maó, Illes Balears, Espagne'
components:
  gallery:
    view-photos: Voir les photos
modules:
  contact:
    find-us: Comment nous trouver ?
    contact-us: Nous contacter
  header:
    book: Réserver maintenant
    book-short: Réserver
  nav:
    hotel: Hôtel
    rooms: Chambres
    services: Services
    gallery: Galerie
    contact: Contact
    legals: Mentions légales
    confidentiality: Politique de confidentialité
    sales: Conditions générales de vente
  footer:
    copyright: Tous droits réservés
pages:
  404:
    title: Page introuvable
    subtitle: 'Désolé, cette page n''existe pas ou a été déplacée'
    contact-us: Nous contacter
    back-home: 'Retour à l''accueil'
  contact:
    title: Nous contacter
    helper:
      text: Besoin de nous contacter ? Remplissez le formulaire pour nous envoyer un e-mail ou contactez-nous via
      postal-address: Adresse postale
      phone-number: Numéro de téléphone
    form:
      full-name: Nom complet
      email: E-mail
      subject: Sujet
      message: Message
      submit: Envoyer
    response:
      success: Message envoyé !
      error: 'Erreur lors de l''envoi de l''email. Veuillez réessayer.'
  rooms:
    headline:
      suptitle: Six chambres uniques
      title: Nos Chambres
      intro:
        'C''est en conservant la simplicité élégante d''origine que les chambres ont été aménagées et décorées avec des
        couleurs chaudes inspirées de la nature vous offrant un confort douillet et un design épuré. Elles sont toutes
        différentes et arborent des papiers peints raffinés des maisons Ananbô, Isidore Leroy ou Casamance, des tissus
        naturels italiens comme le lin ou la laine et un mobilier contemporain.'
    features:
      suptitle: Tout ce dont vous avez besoin
      title: Notre Offre
      description:
        El Cónsul Maó dispose de 5 chambres réparties sur 2 niveaux et 1 chambre pouvant accueillir des personnes à mobilité réduite au rez de chaussé.
      notice: 'Toutes les chambres sont non-fumeur.{0}L’hôtel ne dispose pas d’ascenseur.'
      listing:
        - 'Le choix d''oreillers avant votre arrivée: plumes, synthétique, ferme, moyen ou moelleux'
        - TV tablette sur demande dans votre chambre avec Wi-Fi
        - Wi-Fi haut débit
        - Sèche-cheveux et Miroir grossissant
        - Coffre-fort
        - Mini frigo et Minibar disponible près de la réception
        - Bouteille d’eau filtrée dans la chambre
        - Parasol disponible à la réception sur demande
        - Serviette de plage (fouta)
    listing:
      - name: Chambre 1
        suptitle: Chambre accessible double
        description:
          'Chambre adaptée pour les personnes à mobilité réduite, elle est située au rez de chaussée. Cette chambre de
          19.2m² est équipée d’un grand lit double 180x200cm. Un papier peint texturé rafia, de la maison Elitis donne
          une touche naturelle et contemporaine. Hauts plafonds de 3.20m avec des poutres d’origine. Les sols sont en
          béton ciré pour un look contemporain. Cette chambre est située au rez de chaussée'
      - name: Chambre 2
        suptitle: Chambre double supérieure
        description:
          'Cette chambre de 20.7m² est équipée d’un grand lit double 180x200cm séparable en 2 lits simples au besoin.
          Dispose de 2 fenêtres. Un joli papier peint panoramique avec un paysage de forêt côtière, de la maison
          Isidore Leroy vous invite aux voyages. Un design contemporain avec des matières naturelles. Un plafond
          avec des poutres apparentes d’origine, jusqu’à 3m de hauteur. Les sols sont en carreaux de ciment typiques
          à Minorque et fabriqués artisanalement à Mahón. Cette chambre est équipée d’un bureau et est située au
          1er niveau. La salle de bain est équipée d’une douche à l’italienne.'
      - name: Chambre 3
        suptitle: Chambre double supérieure
        description:
          'Cette chambre de 23m² est équipée d’un grand lit double 180x200cm séparable en 2 lits simples
          au besoin. Dispose d’une fenêtre avec vue sur le patio et un velux sur le toit. Un papier peint texturé rafia,
          de la maison Elitis donne une touche naturelle et contemporaine. Le coin bureau de la chambre a un plafond
          mansardé jusqu’à 4.30m de hauteur. Les sols sont en carreaux de ciment typiques à Minorque et fabriqués
          artisanalement à Mahón. Cette chambre est située au 1er niveau. La salle de bain est équipée d’une douche à
          l’italienne.'
      - name: Chambre 4
        suptitle: Petite chambre double
        description:
          'Cette chambre de 17.5m² est équipée d’un grand lit double 180x200cm. Dispose d’une belle fenêtre ceinturée
          avec vue sur le patio. Un plafond mansardé avec des poutres apparentes d’origine, jusqu’à 4.30m de hauteur.
          Un papier peint texturé dans les tons orangers de la maison Casamance donne une touche naturelle et
          contemporaine. Les sols en carreaux de ciment sont d’origine avec un motif typique de Minorque. Cette chambre
          est située au 1er niveau. La salle de bain est équipée d’une douche à l’italienne.'
      - name: Chambre 5
        suptitle: Chambre double supérieure
        description:
          'Cette chambre de 20.7m² est équipée d’un grand lit double 180x200cm et d’un canapé lit 80x200cm pouvant
          accueillir une personne. Dispose de 2 fenêtres. Un papier peint panoramique avec un paysage de pins et
          cyprès dans les tons sépia, de la maison Ananbô donne une touche reposante. Un plafond mansardé avec des
          poutres apparentes d’origine, jusqu’à 4m de hauteur. Un design contemporain avec des matières
          naturelles. Les sols sont en carreaux de ciment typiques à Minorque et fabriqués artisanalement à Mahón.
          Chambre située au 2ème niveau. La salle de bain est équipée d’une douche à l’italienne.'
      - name: Chambre 6
        suptitle: Chambre double supérieure
        description:
          'Cette chambre de 23.2m² est équipée d’un grand lit double 180x200cm et d’un canapé lit 80x200cm pouvant
          accueillir une personne. Une longue fenêtre horizontale avec vue sur la vieille ville de Mahon et un grand
          velux. Un plafond mansardé avec des poutres apparentes. Le design se caractérise par des surfaces et
          couleurs naturelles. Un joli papier peint panoramique en grisaille, de la maison Isidore Leroy donne une
          note contemporaine. Les sols sont en carreaux de ciment typiques à Minorque et fabriqués artisanalement à
          Mahón. Chambre située au 2ème niveau. La salle de bain est équipée d’une douche à l’italienne.'
  index:
    banner:
      suptitle: Bienvenue à
    project:
      suptitle: Le Projet
      title: Votre hôtel boutique lifestyle à Mahón
      content:
        'Une ambiance conviviale et accueillante, {0} toutes différentes et décorées avec goût, un patio calme
        et ombragé... El Cónsul Maó est situé dans le centre historique de Mahon, à deux pas du théâtre principal et des
        nombreux restaurants de la ville.'
      link: 6 chambres
      learn-more: 'En savoir plus sur l''hôtel'
    ecology:
      suptitle: Écologie
      title: Un hôtel éco responsable
      content:
        'Des mesures concrètes et durables afin de diminuer notre empreinte carbone: Diminuer l’utilisation du
        plastique, miser sur les produits biologiques et locaux, utilisation des eaux de pluie... afin de respecter l’orientation
        écologique de Minorque.'
    hospitality:
      suptitle: Hospitalité
      title: Votre bien-être
      content:
        '@:project.name prend soin de chacun de ses hôtes avec un accueil personnalisé, accès à sa cuisine ouverte,
        la possibilité de choisir le confort de vos oreillers...'
    booking:
      suptitle: Envie de vacances ?
      title: Préparez votre séjour dès maintenant
      button: Réserver mon séjour
