import type { RouteLocation } from 'vue-router'
import type { Locale } from 'vue-i18n'

export const isProduction = import.meta.env.MODE === 'production'

export function getLocalizedRoute(route: RouteLocation, locale: Locale) {
  if (route.fullPath === '/')
    return `/${locale}`

  return `/${locale}/${route.fullPath.split('/').slice(2).join('/')}`
}
