import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

import type { UserModule } from '~/types'

export const install: UserModule = ({ app, router, isClient }) => {
  if (isClient) {
    Sentry.init({
      app,
      dsn: <string>import.meta.env.VITE_SENTRY_DNS,
      release: APP_VERSION,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
      ],
      environment: import.meta.env.MODE,
      tracesSampleRate: 1.0,
      // debug: import.meta.env.MODE !== 'production',
    })

    app.config.errorHandler = (err) => {
      Sentry.captureException(err)
    }
  }
}
