export const ROUTES = {
  HOME: '/',
  ROOMS: '/rooms',
  GALLERY: '/gallery',
  CONTACT: '/contact',
  SERVICES: '/services',
  HOTEL: '/hotel',
  LEGALS: '/legal-notice',
  CONFIDENTIALITY: '/privacy-policy',
  SALES: '/sales-terms',
  LOCATION: '/location',
}
