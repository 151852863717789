<script setup lang="ts">
import { onErrorCaptured, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'

import { TheCookieConsent } from '~/components/single-instance'
import { getLocalizedRoute } from '~/common/utils'
import { ECM } from '~/types'

const { t } = useI18n()
const route = useRoute()

const { availableLocales, locale } = useI18n()

useHead({
  htmlAttrs: {
    lang: locale.value,
  },
  title: t('project.name'),
  link: [
    {
      rel: 'icon',
      type: 'image/svg+xml',
      href: '/favicon.svg',
    },
    ...availableLocales.map(locale => ({
      rel: 'alternate',
      href: `${import.meta.env.VITE_APP_URL}${getLocalizedRoute(route, locale)}`,
      hreflang: locale,
    })),
    {
      rel: 'alternate',
      hreflang: 'x-default',
      href: `${import.meta.env.VITE_APP_URL}${getLocalizedRoute(route, ECM.Locale.EN)}`,
    },
  ],
  meta: [
    {
      name: 'description',
      content: t('project.description'),
    },
    {
      name: 'theme-color',
      content: '#F2EFE9',
    },
    {
      property: 'og:locale',
      content: locale.value,
    },
  ],
})

const router = useRouter()

onErrorCaptured((err: BaseError) => {
  if (err.status === 404)
    router.push({ name: 'all' })
})

const isLoaded = ref(false)

onMounted(() => {
  isLoaded.value = true
})
</script>

<template>
  <RouterView v-slot="{ Component }">
    <transition v-show="isLoaded"
                appear
                enter-active-class="duration-1000"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
    >
      <div>
        <Component :is="Component" />
        <template v-if="isLoaded">
          <TheCookieConsent />
        </template>
      </div>
    </transition>
  </routerview>
</template>

<style>
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  width: 100%;
  height: 3px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: theme('zIndex.50');
  background: theme('colors.accent.500');
}
</style>
