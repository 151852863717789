project:
  name: El Cónsul Maó
  description: The former French consulate in Mahón becomes a small Cozy hotel - 6 rooms - opening July 2022
  address: 'Carrer d''en Deià, 30, 07702 Maó, Illes Balears, Spain'
components:
  gallery:
    view-photos: View photos
modules:
  contact:
    find-us: How to find us?
    contact-us: Contact us
  header:
    book: Book now
    book-short: Book
  nav:
    hotel: Hotel
    rooms: Rooms
    services: Services
    gallery: Gallery
    contact: Contact
    legals: Legal notice
    confidentiality: Privacy policy
    sales: Sales Terms
  footer:
    copyright: All rights reserved
pages:
  404:
    title: Page not found
    subtitle: 'Sorry, this page was not found or does not exist anymore'
    contact-us: Contact us
    back-home: Back to home
  contact:
    title: Get in touch
    helper:
      text: Need to get in touch with us? Either fill out the form to send us an email or contact us via
      postal-address: Postal address
      phone-number: Phone number
    form:
      full-name: Full name
      email: Email
      subject: Subject
      message: Message
      submit: Submit
    response:
      success: Message sent !
      error: Error sending email. Try Again.
  rooms:
    headline:
      suptitle: Six unique bedrooms
      title: Our Rooms
      intro:
        'It is by preserving the original elegant simplicity that the rooms have been fitted out and decorated with warm
        colors inspired by nature, offering you cozy comfort and a refined design. They are all different and feature
        refined wallpapers from Ananbô, Isidore Leroy or Casamance, natural Italian fabrics such as linen or wool and
        contemporary furniture.'
    features:
      suptitle: Everything your need
      title: Our Offer
      description:
        El Cónsul Maó has 5 bedrooms spread over 2 levels and 1 bedroom that can accommodate people with reduced mobility on the ground floor.
      notice: 'All rooms are non-smoking.{0}The hotel does not have an elevator.'
      listing:
        - 'The choice of pillows before your arrival: feather, synthetic, firm, medium or soft'
        - Tablet TV on demand in your room with Wi-Fi
        - High Speed Wi-Fi
        - Hairdryer and magnifying mirror
        - Safe
        - Mini fridge and Minibar available near the reception
        - Bottle of filtered water in the room
        - Parasol available at reception on request
        - Beach towel (fouta)
    listing:
      - name: Room 1
        suptitle: Accessible double room
        description:
          'Room adapted for people with reduced mobility, it is located on the ground floor. This 19.2m² room is
          equipped with a large double bed 180x200cm. A rafia textured wallpaper from Elitis gives a natural and
          contemporary touch. High ceilings of 3.20m with original beams. The floors are polished concrete for a
          contemporary look.'
      - name: Room 2
        suptitle: Superior double room
        description:
          'This 20.7m² room is equipped with a large 180x200cm double bed that can be separated into 2 single beds if
          necessary. Has 2 windows. A beautiful panoramic wallpaper with a coastal forest landscape, from Maison
          Isidore Leroy invites you to travel. A contemporary design with natural materials. A ceiling with original
          exposed beams, up to 3m high. The floors are in typical Menorcan cement tiles and handcrafted in Mahón.
          This room is equipped with a desk and is located on the 1st level. The bathroom is equipped with a
          walk-in shower.'
      - name: Room 3
        suptitle: Superior double room
        description:
          'This 23m² room is equipped with a large double bed 180x200cm separable into 2 single beds if needed. Has a
          window with a view of the patio and a skylight on the roof. A rafia textured wallpaper, of ELitis House
          gives a natural and contemporary touch. The office area of the bedroom has a ceiling attic up to 4.30m in
          height. The floors are in typical cement tiles in Menorca and made craftsmanship in Mahón. This room is
          located on the 1st level. The bathroom is equipped with a walk-in shower.'
      - name: Room 4
        suptitle: Small double bedroom
        description:
          'This 17.5m² room is equipped with a large double bed 180x200cm. Features a beautiful belted window with a
          view of the patio. A sloping ceiling with original exposed beams, up to 4.30m high. A textured wallpaper in
          the orange tones of the Casamance house gives a natural and contemporary touch. The cement tile floors are
          original with a typical Menorcan pattern. This room is located on the 1st level. The bathroom is equipped
          with a walk-in shower.'
      - name: Room 5
        suptitle: Superior double bedroom
        description:
          'This 20.7m² room is equipped with a large double bed 180x200cm and a sofa bed 80x200cm that can
          accommodate one person. Has 2 windows. A panoramic wallpaper with a landscape of pines and cypresses in
          sepia tones, from Ananbô, gives a relaxing touch. A sloping ceiling with original exposed beams, up to
          4mtrs high. A contemporary design with natural materials. The floors are in typical Menorcan cement tiles
          and handcrafted in Mahón. Bedroom located on the 2nd level. The bathroom is equipped with a walk-in shower.'
      - name: Room 6
        suptitle: Superior double bedroom
        description:
          'This 23.2m² room is equipped with a large double bed 180x200cm and a sofa bed 80x200cm that can
          accommodate one person. A long horizontal window with a view of the old town of Mahon and a large skylight.
          A sloping ceiling with exposed beams. The design is characterized by natural surfaces and colours. A
          pretty panoramic wallpaper in grisaille, from the Isidore Leroy house, gives a contemporary note. The
          floors are in typical Menorcan cement tiles and handcrafted in Mahón. Bedroom located on the 2nd level.
          The bathroom is equipped with a walk-in shower.'
  index:
    banner:
      suptitle: Welcome to
    project:
      suptitle: The Project
      title: Your lifestyle boutique hotel in Mahón
      content:
        'A friendly and welcoming atmosphere, {0} all different and tastefully decorated, a quiet and shady patio...
        @:project.name is located in the historic center of Mahón, a stone''s throw from the main theater and the town''s
        many restaurants.'
      link: 6 rooms
      learn-more: Learn more about the hotel
    ecology:
      suptitle: Ecology
      title: An eco-responsible hotel
      content:
        'Concrete and sustainable measures to reduce our carbon footprint: Reduce the use of plastic, bet on local
        products, use of rainwater... in order to respect the ecological orientation of Menorca.'
    hospitality:
      suptitle: Hospitality
      title: Your well-being
      content:
        '@:project.name takes care of each of its guests with a personalized welcome, access to its open kitchen, the
        possibility of choosing the comfort of your pillows...'
    booking:
      suptitle: Need a holiday?
      title: Prepare your stay now
      button: Book your stay
