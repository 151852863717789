<script setup>
import { reactive } from 'vue'
import { CalendarIcon } from '@heroicons/vue/24/outline/index.js'

import LogoElConsulMao from '~/assets/logos/logo-white.svg'
import { useTranslation } from '~/composables'
import LocalesDropdown from '~/components/ui/locales-dropdown.vue'
import { BOOKING_URL } from '~/common/types'
import { ROUTES } from '~/common/routes'

const { withLocale } = useTranslation()

const navigation = reactive([
  { name: 'modules.nav.hotel', path: ROUTES.HOTEL },
  { name: 'modules.nav.rooms', path: ROUTES.ROOMS },
  { name: 'modules.nav.services', path: ROUTES.SERVICES },
  { name: 'modules.nav.gallery', path: ROUTES.GALLERY },
])
</script>

<template>
  <header class="lg:sticky lg:top-0 z-40 bg-primary-500 border-b border-gray-400">
    <nav class="border-b border-gray-400 xs:border-0 max-w-7xl mx-auto px-4" aria-label="Top">
      <div class="w-full py-4 flex items-center justify-between border-b border-secondary-500 lg:border-none">
        <div class="w-4/12 xs:w-5/12 flex items-center">
          <LocalesDropdown class="lg:hidden" />
          <div class="hidden space-x-8 lg:block">
            <router-link v-for="link in navigation"
                         :key="link.name"
                         :to="withLocale(link.path)"
                         class="text-white text-sm uppercase hover:underline"
            >
              {{ $t(link.name) }}
            </router-link>
          </div>
        </div>
        <div class="w-4/12 xs:w-2/12 flex justify-center">
          <router-link :to="withLocale(ROUTES.HOME)">
            <span class="sr-only">
              {{ $t('project.name') }}
            </span>
            <img :src="LogoElConsulMao"
                 alt="El Cónsul Maó"
                 width="83"
                 height="48"
            >
          </router-link>
        </div>
        <div class="w-4/12 xs:w-5/12 flex justify-end items-center space-x-8">
          <LocalesDropdown class="hidden lg:block" />
          <a :href="BOOKING_URL"
             target="_blank"
             class="hidden xs:inline-flex items-center space-x-2 bg-accent-500 py-2 px-4 border border-transparent rounded-md text-base uppercase text-sm font-medium text-white hover:bg-opacity-75"
          >
            <CalendarIcon class="h-4 w-4" aria-hidden="true" />
            <span class="hidden md:inline">
              {{ $t('modules.header.book') }}
            </span>
            <span class="md:hidden">
              {{ $t('modules.header.book-short') }}
            </span>
          </a>
        </div>
      </div>
      <div class="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
        <router-link v-for="link in navigation"
                     :key="link.name"
                     :to="withLocale(link.path)"
                     class="text-white text-sm uppercase"
        >
          {{ $t(link.name) }}
        </router-link>
      </div>
    </nav>
    <a :href="BOOKING_URL"
       target="_blank"
       class="xs:hidden font-sans w-full flex items-center space-x-2 bg-accent-500 py-2 px-4 justify-center border-transparent text-base uppercase text-sm font-medium text-white hover:bg-opacity-75"
    >
      <CalendarIcon class="h-4 w-4" aria-hidden="true" />
      <span>{{ $t('modules.header.book') }}</span>
    </a>
  </header>
</template>
