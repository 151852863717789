<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/24/outline/index.js'

import { England, France, Spain } from '~/assets/flags'
import { ECM } from '~/types'
import { getLocalizedRoute } from '~/common/utils.js'

const route = useRoute()

const { availableLocales, locale } = useI18n()

function getFlagByLocale(locale: string) {
  switch (locale) {
    case ECM.Locale.EN:
      return England
    case ECM.Locale.FR:
      return France
    case ECM.Locale.ES:
      return Spain
  }
}
</script>

<template>
  <Menu v-slot="{ close }" as="div" class="relative z-10">
    <div>
      <MenuButton class="inline-flex text-white uppercase items-center space-x-2 justify-center w-full rounded-md shadow-sm p-2 text-xs md:text-sm font-medium focus:outline-none">
        <span>{{ locale }}</span>
        <ChevronDownIcon class="-mr-1 h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems class="origin-top-right absolute w-20 left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
        <div class="py-1">
          <router-link v-for="(lng, index) in availableLocales"
                       :key="index"
                       v-slot="{ href, navigate }"
                       custom
                       :to="getLocalizedRoute(route, lng)"
          >
            <MenuItem :href="href"
                      as="a"
                      class="text-gray-700 cursor-pointer uppercase group flex items-center px-4 py-2 text-sm space-x-2"
                      @click.prevent="() => { close(); navigate() }"
            >
              <img :src="getFlagByLocale(lng)" alt="Flag" class="w-5 h-auto">
              <span>{{ lng }}</span>
            </MenuItem>
          </router-link>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
