project:
  name: El Cónsul Maó
  description: El antiguo consulado de Francia en Mahón se convierte en un pequeño Cosy hotel - 6 habitación-apertura julio 2022
  address: 'Carrer d''en Deià, 30, 07702 Maó, Illes Balears, España'
components:
  gallery:
    view-photos: Ver fotos
modules:
  contact:
    find-us: ¿Como encontrarnos?
    contact-us: Contáctenos
  header:
    book: Reservar ahora
    book-short: Reservar
  nav:
    hotel: Hotel
    rooms: Habitaciones
    services: Servicios
    gallery: Galería
    contact: Contacto
    legals: Aviso Legal
    confidentiality: Política de privacidad
    sales: Condiciones generales de venta
  footer:
    copyright: Todos los derechos reservados
pages:
  404:
    title: Página no encontrada
    subtitle: 'Lo sentimos, esta página no existe o ha sido movida'
    contact-us: Contáctenos
    back-home: Retorno al inicio
  contact:
    title: Contáctenos
    helper:
      text: ¿Necesita ponerse en contacto con nosotros? Complete el formulario para enviarnos un correo electrónico o contáctenos a través de
      postal-address: Dirección postal
      phone-number: Número de teléfono
    form:
      full-name: Nombre completo
      email: Email
      subject: Asunto
      message: Mensaje
      submit: Enviar
    response:
      success: ¡Mensaje enviado!
      error: Error al enviar correo electrónico. Intentar otra vez.
  rooms:
    headline:
      suptitle: Seis habitaciones únicas
      title: Nuestras habitaciones
      intro:
        'Manteniendo la elegante sencillez original, las habitaciones han sido amuebladas y decoradas con colores
        cálidos inspirados en la naturaleza, ofreciéndole un confort acogedor y un diseño limpio.
        Cada habitación es diferente y presenta refinados papeles pintados de las casas Ananbô, Isidore Leroy o
        Casamance, tejidos naturales italianos como el lino o la lana y muebles contemporáneos.'
    features:
      suptitle: Todo lo que necesitas
      title: Nuestra oferta
      description:
        El Cónsul Maó dispone de 5 habitaciones repartidos en 2 niveles y 1 dormitorio que puede alojar a personas con movilidad reducida en la planta baja.
      notice: 'Todas las habitaciones son para no fumadores.{0}El hotel no tiene ascensor.'
      listing:
        - 'La elección de almohadas antes de su llegada: pluma, sintética, firme, media o blanda'
        - Tablet TV a la carta en tu habitación con wifi
        - Wi-Fi de alta velocidad
        - Secador de pelo y espejo de aumento
        - Caja fuerte
        - Mini nevera y Minibar disponibles cerca de la recepción
        - Botella de agua filtrada en la habitación
        - Sombrilla disponible en recepción bajo petición
        - Toalla de playa (fouta)
    listing:
      - name: Habitación 1
        suptitle: Habitación doble accesible
        description:
          'Habitación adaptada para personas con movilidad reducida y se encuentra en la planta baja. Esta habitación
          de 19,2m² está equipada con una gran cama doble de 180x200cm. Un papel pintado con textura de rafia de Elitis
          le da un toque natural y contemporáneo. Techos altos de 3,20m con vigas originales. Los suelos son de
          concreto pulido para darles un aspecto contemporáneo'
      - name: Habitación 2
        suptitle: Habitación doble superior
        description:
          'Esta habitación de 20,7m² está equipada con una gran cama doble de 180x200cm que se puede separar en 2
          camas individuales si es necesario. Tiene 2 ventanas. Un bonito papel pintado panorámico con un paisaje de
          bosque costero, de Maison Isidore Leroy, te invita a viajar. Un diseño contemporáneo con materiales
          naturales. Un techo con vigas vistas originales, de hasta 3m de altura. Los suelos son de baldosa
          hidráulica típica menorquina y hechos a mano en Mahón. Esta habitación está equipada con un escritorio y se
          encuentra en la primera planta. El baño está equipado con una ducha a ras de suelo.'
      - name: Habitación 3
        suptitle: Habitación doble superior
        description:
          'Esta habitación de 23m² está equipada con una gran cama doble de 180x200cm que se puede separar en 2 camas
          individuales si es necesario. Tiene una ventana que da al patio y una ventana velux en el techo. Un papel
          pintado con textura de rafia de Elitis le da un toque natural y contemporáneo. La zona de despacho del
          dormitorio tiene un techo de mansarda de hasta 4,30m de altura. Los suelos son de baldosa hidráulica típica
          menorquina y hechos a mano en Mahón. Esta habitación se encuentra en la primera planta. El baño está equipado
          con una ducha a ras de suelo.'
      - name: Habitación 4
        suptitle: Habitación doble pequeño
        description:
          'Esta habitación de 17,5m² está equipada con una gran cama doble de 180x200cm. Cuenta con una hermosa
          ventana con cinturón con vista al patio. Un techo de mansarda con vigas originales a la vista, de hasta 4,30m
          de altura. Un papel pintado texturizado en los tonos naranjas de la casa Casamance le da un toque natural y
          contemporáneo. Los suelos de baldosas de cemento son originales con un patrón típico menorquín. Esta habitación
          se encuentra en la primera planta. El baño está equipado con una ducha a ras de suelo.'
      - name: Habitación 5
        suptitle: Habitación doble superior
        description:
          'Esta habitación de 20,7m² está equipada con una gran cama doble de 180x200cm y un sofá cama de 80x200cm
          con capacidad para una persona. Tiene 2 ventanas. Un papel pintado panorámico con un paisaje de pinos y
          cipreses en tonos sepia, de Ananbô, le da un toque relajante. Techo abuhardillado con vigas vistas
          originales, de hasta 4mtrs de altura. Un diseño contemporáneo con materiales naturales. Los suelos son de
          baldosa hidráulica típica menorquina y hechos a mano en Mahón. Habitación situada en la 2ª planta. El baño
          está equipado con una ducha a ras de suelo.'
      - name: Habitación 6
        suptitle: Habitación doble superior
        description:
          'Esta habitación de 23,2m² está equipada con una gran cama doble de 180x200cm y un sofá cama de 80x200cm
          que puede acomodar a una persona. Un largo ventanal horizontal con vistas al casco antiguo de Mahón y un
          gran lucernario. Un techo inclinado con vigas vistas. El diseño se caracteriza por superficies y colores
          naturales. Un bonito papel pintado panorámico en grisalla, de la casa Isidore Leroy, le da una nota
          contemporánea. Los suelos son de baldosa hidráulica típica menorquina y hechos a mano en Mahón. Habitación
          situada en la 2ª planta. El baño está equipado con una ducha a ras de suelo.'
  index:
    banner:
      suptitle: Bienvenido a
    project:
      suptitle: El proyecto
      title: Su hotel boutique Livestyle en Mahón
      content:
        'Un ambiente agradable y acogedor, {0} todas diferentes y decoradas con gusto, un patio tranquilo
        y sombreado... El Cónsul Maó está situado en el centro histórico de Mahón, a un paso del teatro principal y de los
        numerosos restaurantes de la ciudad'
      link: 6 habitaciones
      learn-more: Más información sobre el hotel
    ecology:
      suptitle: Ecológico
      title: Un hotel eco-responsable
      content:
        'Medidas concretas y sostenibles para reducir nuestra huella de carbono: Reducir el uso de plástico, apostar
        por productos locales y biológicos, Utilizar de aguas pluviales… para respetar la orientación ecológica de
        Menorca.'
    hospitality:
      suptitle: Hospitalidad
      title: Tu bienestar
      content:
        '@:project.name cuida a cada uno de sus huéspedes con una acogida personalizada, acceso a su cocina
        abierta, la posibilidad de elegir la comodidad de sus almohadas...'
    booking:
      suptitle: ¿Te apetece unas vacaciones?
      title: Prepara tu estancia ahora
      button: Reservar mi estancia
