<script setup>
import { onMounted, ref } from 'vue'
import { useEventBus } from '@vueuse/core'
import Cookies from 'js-cookie'
import { XMarkIcon } from '@heroicons/vue/24/outline/index.js'

import { ROUTES } from '~/common/routes'
import { EVB_USER_CONSENT } from '~/common/types'
import { useTranslation } from '~/composables'

const { withLocale } = useTranslation()

const { emit } = useEventBus(EVB_USER_CONSENT)

const hasConsent = ref(JSON.parse(Cookies.get('user-consent') || false))

function accept() {
  hasConsent.value = true
  Cookies.set('user-consent', true, { expires: 365, path: '/' })
  emit()
}

// Auto accept after 10s
onMounted(() => setTimeout(accept, 10000))
</script>

<template>
  <transition appear
              enter-active-class="duration-300"
              enter-from-class="translate-y-full"
              enter-to-class="translate-0"
              leave-active-class="duration-300"
              leave-to-class="translate-y-full"
  >
    <div v-if="!hasConsent"
         class="fixed bottom-0 left-0 w-64 z-50 box-shadow"
    >
      <div class="relative p-6 bg-slate-800 bg-opacity-90 shadow-md border-t border-r border border-gray-400">
        <span class="cursor-pointer text-gray-400 absolute top-0 right-0 border-b border-l border-gray-400 hover:text-white"
              @click="accept"
        >
          <XMarkIcon class="h-5 w-5"
                     aria-hidden="true"
          />
        </span>
        <p class="font-medium text-white text-xs">
          En poursuivant votre navigation, vous reconnaissez avoir lu et accepté notre
          <router-link :to="withLocale(ROUTES.LEGALS)"
                       class="underline"
          >
            politique de cookie
          </router-link>
        </p>
      </div>
    </div>
  </transition>
</template>
